<template>
  <div class="container">
    <div class="test-setting">
      <div class="mode-check">
        <h5>
          <b>{{ this.toeflTitle }}</b>
        </h5>
        <hr />
        <div class="reading_listening-test mar">
          <el-radio-group v-model="mode" size="medium">
            <el-radio-button label="0">
              {{ $t("toefl.default") }}
            </el-radio-button>
            <el-radio-button label="1">
              {{ $t("toefl.strict") }}
            </el-radio-button>
          </el-radio-group>
        </div>
        <div class="show-mode">
          <img v-show="mode === '0'" src="@/assets/TOEFL.png" />
          <img v-show="mode === '1'" src="@/assets/TOEFLReal.png" />
        </div>
      </div>
      <hr class="hr" />
      <div class="system-check">
        <h5>
          <b>{{ $t("toefl.system_check") }}</b>
        </h5>
        <hr />
        <div class="mar">
          <h6>
            <span class="text-success" v-show="chrome">
              <i class="fas fa-check-square"></i>
            </span>
            <span class="text-danger" v-show="!chrome">
              <i class="fas fa-times-circle"></i>
            </span>
            {{ $t("toefl.device_and_browser") }}:
            <small class="text-success" v-if="chrome">
              {{ $t("toefl.success") }}
            </small>
            <small class="text-danger" v-else>
              {{ $t("toefl.tips_device_and_browser") }}
            </small>
          </h6>
        </div>
        <hr />
        <div class="check-audio-files mar">
          <h5
            v-if="filesLoading"
            v-loading="filesLoading"
            element-loading-spinner="el-icon-loading"
          >
            {{ $t("toefl.loading_files") }}
          </h5>
          <h5 v-else>
            <span class="text-success" v-show="isFiles">
              <i class="fas fa-check-square"></i>
            </span>
            <span class="text-danger" v-show="!isFiles">
              <i class="fas fa-times-circle"></i>
            </span>

            {{ $t("toefl.loading_files") }}:
            <small class="text-success" v-if="isFiles">
              {{ $t("toefl.success") }}
            </small>
            <small class="text-danger" v-else v-html="$t('toefl.file-fixed')">
            </small>
          </h5>
        </div>

        <div
          v-show="
            type === 'mock' ||
              type === 'quick' ||
              type === 'speaking' ||
              choiceType === 'speaking'
          "
        >
          <hr />
          <div class="check-audio-files mar">
            <h6>
              <span class="text-success" v-show="canRecord">
                <i class="fas fa-check-square"></i>
              </span>
              <span class="text-danger" v-show="!canRecord">
                <i class="fas fa-times-circle"></i>
              </span>
              {{ $t("toefl.load_recording") }}:
              <small class="text-success" v-if="canRecord">
                {{ $t("toefl.success") }}
              </small>
              <div v-else>
                <small class="text-danger">
                  <el-popover placement="bottom" width="300" trigger="hover">
                    <img
                      style="display:block;width:100%"
                      src="@/assets/check_mic.png"
                      alt=""
                    />
                    <span slot="reference">
                      {{ $t("toefl.check_mic")
                      }}<i class="fa fa-question-circle"></i>
                    </span>
                  </el-popover>
                </small>
              </div>
            </h6>
          </div>
        </div>
        <div v-show="type !== 'reading' && choiceType !== 'reading'">
          <hr />
          <div class="mar">
            <h6>
              <el-checkbox v-model="output">
                <h6>{{ $t("toefl.sound_output") }}:</h6>
              </el-checkbox>
              <small class="text-success" v-if="output">
                {{ $t("toefl.success") }}
              </small>
              <el-button
                v-if="testAudio"
                icon="el-icon-video-play"
                @click="playAudioTest('audio_listening')"
                size="medium"
                style="width: 100%"
              >
                <span>
                  {{ $t("toefl.click_to_listen") }}
                </span>
              </el-button>
              <el-button
                v-else
                icon="el-icon-video-pause"
                @click="playAudioTest('audio_listening')"
                size="medium"
                style="width: 100%"
              >
                <span>
                  {{ $t("toefl.click_to_pause") }}
                </span>
              </el-button>
            </h6>

            <audio
              id="audio_listening"
              src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/bugle.mp3"
              @ended="testAudio = true"
            ></audio>
            <span class="bugle_stop pointer text-success" style="display: none">
              <i class="control_audio fas fa-volume-up"></i>
            </span>
          </div>
        </div>
        <div
          v-show="
            type === 'mock' ||
              type === 'quick' ||
              type === 'speaking' ||
              choiceType === 'speaking'
          "
        >
          <hr />
          <div class="speaking-test mar">
            <h6>
              <el-checkbox v-model="input" v-if="canRecord">
                <h6 style="margin-right:10px">
                  {{ $t("toefl.sound_input") }}:
                </h6>
              </el-checkbox>
              <span class="text-danger" v-else>
                <i class="fas fa-times-circle"></i>
                {{ $t("toefl.sound_input") }}:
              </span>
              <small class="text-success" v-if="input">
                {{ $t("toefl.success") }}
              </small>
              <div v-if="canRecord">
                <div>
                  <el-button
                    @click="readyOriginal"
                    type=""
                    icon="el-icon-microphone"
                    size="medium"
                    style="width: 100%"
                  >
                    <span v-if="isVoice">
                      {{ $t("toefl.click_to_pause") }}
                    </span>
                    <span v-else>
                      {{ $t("toefl.test_record") }}
                    </span>
                  </el-button>
                </div>
                <div style="margin-top:10px">
                  <el-button
                    v-show="isVoice"
                    @click="readyOriginal"
                    size="medium"
                    style="width: 100%"
                  >
                    <i class="fas fa-record-vinyl"></i>
                    {{ $t("toefl.recording") }}
                  </el-button>
                </div>
                <div style="margin-top:10px">
                  <el-button
                    v-show="isComplete"
                    @click="playAudio('audio')"
                    type="primary"
                    icon="el-icon-video-play"
                    size="medium"
                    style="width: 100%"
                  >
                    {{ $t("toefl.click_to_listen") }}
                  </el-button>
                </div>
              </div>
              <div v-else>
                <small class="text-danger">
                  <el-popover placement="bottom" width="300" trigger="hover">
                    <img
                      style="display:block;width:100%"
                      src="@/assets/check_mic.png"
                      alt=""
                    />
                    <span slot="reference">
                      {{ $t("toefl.check_mic")
                      }}<i class="fa fa-question-circle"></i>
                    </span>
                  </el-popover>
                </small>
              </div>
            </h6>
          </div>
        </div>
      </div>
      <audio id="audio" :src="audio_src" type="audio/wav"></audio>
      <hr />
      <div style="margin-bottom:20px">
        <el-alert
          v-if="canStart"
          :title="
            $t('toefl.Please check all the boxes in System Check above first')
          "
          type="warning"
          show-icon
          :closable="false"
        >
        </el-alert>
      </div>
      <div class="text-center">
        <div v-if="!canStart">
          <el-button
            @click="startTest"
            type="success"
            :disabled="canStart"
            style="width:100%"
          >
            {{ $t("courses.start") }}
          </el-button>
        </div>
        <div v-else>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              $t('toefl.Please check all the boxes in System Check above first')
            "
            placement="top"
          >
            <el-button type="info" style="width:100%">
              {{ $t("courses.start") }}
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import HZRecorder from "js-audio-recorder";
import ToeflMixin from "@/mixins/toefl.js";
import Toefl from "@/apis/toefl.js";
import $ from "jquery";

export default {
  metaInfo() {
    return {
      title: "TOEFL Set Test - " + this.CompanyName
    };
  },
  components: {},
  mixins: [ToeflMixin],
  props: [],
  data() {
    return {
      mode: "0",
      isComplete: false,
      isVoice: false,
      audio_src: {},
      style: true,
      recorder: {},
      filesLoading: false,
      fileError: 0,
      // canStart: true,
      examId: null,
      canRecord: false,
      input: false,
      output: false,
      testAudio: true,
      // chrome: this.isChromium(),
      chrome: true,
      pc: this.isPC(),
      IOS: this.isIOS(),
      stream: null,
      toeflTitle: ""
    };
  },
  computed: {
    isFiles() {
      let isFiles = true;
      if (this.fileError > 0) {
        isFiles = false;
      }
      return isFiles;
    },
    title() {
      return this.$route.query.title;
    },
    chapter_id() {
      return this.$route.query.chapter_id;
    },
    type() {
      return this.$route.query.testType;
    },
    choiceType() {
      return this.$route.query.type;
    },
    version() {
      return parseInt(this.$route.query.version);
    },
    canStart() {
      let canStart = true;
      if (
        this.canRecord &&
        this.input &&
        this.output &&
        this.chrome &&
        this.isFiles
      ) {
        canStart = false;
      }
      return canStart;
    }
  },
  watch: {
    examId() {
      this.checkFiles();
    }
  },
  mounted() {
    this.getExamId();
    if (
      this.type === "mock" ||
      this.type === "quick" ||
      this.type === "speaking" ||
      this.type === undefined
    ) {
      if (
        navigator.mediaDevices.getUserMedia ||
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia
      ) {
        this.getUserMedia({ audio: true }); // 调用用户媒体设备，访问摄像头、录音
      } else {
        alert(this.$t("toefl.tips_device_and_browser"));
      }
      if (this.type === undefined) {
        if (this.choiceType !== "speaking") {
          this.input = true;
          this.canRecord = true;
          if (this.choiceType === "reading") {
            this.output = true;
            this.canRecord = true;
          }
        }
      }
    } else {
      if (this.type === "reading") {
        this.input = true;
        this.output = true;
        this.canRecord = true;
      } else {
        this.input = true;
        this.canRecord = true;
      }
    }
    // if (this.type === "reading") {
    //   this.output = true;
    // }
  },

  methods: {
    getUserMedia(constrains) {
      let that = this;
      if (navigator.mediaDevices.getUserMedia) {
        // 最新标准API
        navigator.mediaDevices
          .getUserMedia(constrains)
          .then(stream => {
            that.success(stream);
          })
          .catch(err => {
            that.error(err);
          });
      } else if (navigator.webkitGetUserMedia) {
        // webkit内核浏览器
        navigator
          .webkitGetUserMedia(constrains)
          .then(stream => {
            that.success(stream);
          })
          .catch(err => {
            that.error(err);
          });
      } else if (navigator.mozGetUserMedia) {
        // Firefox浏览器
        navigator
          .mozGetUserMedia(constrains)
          .then(stream => {
            that.success(stream);
          })
          .catch(err => {
            that.error(err);
          });
      } else if (navigator.getUserMedia) {
        // 旧版API
        navigator
          .getUserMedia(constrains)
          .then(stream => {
            that.success(stream);
          })
          .catch(err => {
            that.error(err);
          });
      }
    },
    // 成功的回调函数
    success(stream) {
      console.log("Clicked Allow, enabled successfully!");
      stream = {
        sampleBits: 16,
        sampleRate: 48000,
        numChannels: 1
      };
      this.stream = stream;
      this.recorder = new HZRecorder(stream);
      this.canRecord = true;
    },
    // 异常的回调函数
    error(error) {
      console.log("Access to user media device failed:", error.name, error.message);
    },
    async checkFiles() {
      const url = await Toefl.checkFiles(this.examId);
      if (url) {
        this.files = url.urls;
        this.filesLoading = true;
        let vm = this;
        this.files.forEach((url, index) => {
          $(".test-setting").prepend(
            `<audio src="${url}" class="audio${index}"></audio>`
          );
          let vid = $(`.audio${index}`)[0];
          if (vid) {
            vid.onerror = function() {
              vm.fileError++;
            };
          } else {
            vm.fileError++;
          }
        });
        // this.fileError = 1;
        this.filesLoading = false;
      }
    },
    //重置录音
    resetAudio() {
      let vm = this;
      this.$nextTick(() => {
        try {
          window.AudioContext =
            window.AudioContext || window.webkitAudioContext;
          navigator.getUserMedia =
            navigator.getUserMedia || navigator.webkitGetUserMedia;
          window.URL = window.URL || window.webkitURL;
        } catch (e) {
          alert("No web audio support in this browser!");
        }
        navigator.getUserMedia(
          { audio: true },
          function(stream) {
            vm.recorder = new HZRecorder(stream);
            vm.canRecord = true;
          },
          function() {}
        );
      });
    },
    //录音开始和结束
    readyOriginal() {
      // let recorder = this.recorder;
      if (!this.isVoice) {
        this.recorder && this.recorder.start();
        this.isVoice = true;
        this.isComplete = false;
      } else {
        this.isVoice = false;
        this.recorder && this.recorder.stop();
        this.isComplete = true;
        this.audio_src = window.URL.createObjectURL(this.recorder.getWAVBlob());
      }
    },
    //播放录音
    playAudio(id) {
      let audio = document.getElementById(id);
      audio.play();
      this.resetAudio();
    },
    playAudioTest(id) {
      let audio = document.getElementById(id);

      if (this.testAudio) {
        this.testAudio = false;
        audio.currenTime = 0;
        audio.play();
      } else {
        this.testAudio = true;
        audio.pause();
      }
    },
    //开始考试
    async getExamId() {
      let res = null;
      // 快速
      if (
        this.chapter_id === undefined &&
        this.type === "quick" &&
        this.title !== undefined
      ) {
        res = await Toefl.getExamId({
          title: this.title,
          is_new: this.version,
          is_quick_mock: 1
        });
      }

      //单元
      if (
        this.chapter_id === undefined &&
        this.type !== "quick" &&
        this.type !== "mock" &&
        this.title !== undefined
      ) {
        res = await Toefl.getExamId({
          title: this.title,
          is_new: this.version,
          type: this.type
        });
      }
      // 单篇
      if (
        this.title === undefined &&
        this.chapter_id !== undefined &&
        this.type === undefined
      ) {
        res = await Toefl.getExamId({
          chapter_id: this.chapter_id,
          is_new: this.version
        });
      }
      //整份
      if (
        this.title !== undefined &&
        this.chapter_id === undefined &&
        this.type === "mock"
      ) {
        res = await Toefl.getExamId({
          title: this.title,
          is_new: this.version
        });
      }
      this.examId = res.exam_id;
      this.toeflTitle = res.toefl_title;
    },
    //开始考试
    startTest() {
      this.routerPush({
        name: "MToeflTest",
        query: {
          id: this.examId,
          mode: this.mode
        }
      });
    }
  }
};
</script>

<style scoped>
.test-setting {
  margin: 20px 0;
}
.hr {
  border-bottom: 2px solid var(--themeColor);
}
.show-mode {
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ccc;
}
.show-mode img {
  width: 100%;
}
.mar >>> .el-checkbox__inner {
  border: 1px solid #f56c6c;
}
.mar >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  border: 1px solid var(--themeColor);
}
h5 > span {
  font-size: 16px;
}
::v-deep .el-checkbox {
  margin-bottom: 0;
}
::v-deep .el-button {
  margin-top: 0;
}
::v-deep .el-checkbox__label {
  padding-left: 2px;
}
::v-deep .el-checkbox__inner::after {
  border-width: 2px;
}
</style>
